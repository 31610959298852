.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  box-shadow: 0px 4px 16px 0px #00000042;
  position: relative;
  background: #ffffff;
  max-height: 100px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 15px 0%;
  border: 0.5px none #0000007a;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    justify-content: space-between;
    max-height: 200px;
    padding-bottom: 0px;
  }
}
.container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.menu {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___2Ksq {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___2Ksq {
    padding: 0px;
  }
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  margin-left: 0px;
  margin-right: 0px;
  min-width: 0;
  padding: 8px 0px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.homeButton {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 0px 8px 8px;
  border-width: 1px;
  border-style: none;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 70%;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    max-width: 70%;
    align-self: center;
  }
}
.column {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-end;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column {
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-right: 80px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button:global(.__wab_instance) {
    margin-top: -80px;
    margin-right: 0px;
  }
}
.svg__g2Scm {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text {
  font-family: "Oxygen", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    font-size: 12px;
  }
}
.svg___6WsUc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox___1SJcg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 50px;
  margin-right: -23px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___1SJcg {
    margin-right: 50px;
    justify-content: center;
    align-items: center;
  }
}
.shareYourProductButton {
  width: auto;
  height: auto;
  max-width: 100%;
  text-align: right;
  padding-bottom: 0px;
  font-family: "Oxygen", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .shareYourProductButton {
    text-align: center;
  }
}
.h6 {
  height: auto;
}
