.resourceCard {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  flex-shrink: 1;
  position: relative;
  cursor: pointer;
  min-width: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    width: auto;
    height: 50px;
    min-width: 80px;
    min-height: 80px;
  }
}
.freeBox__ltkgq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 15px;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ltkgq {
    padding: 0px;
  }
}
.freeBox___3N1Gg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetTitle {
  font-size: 20px;
  font-weight: 600;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 18px;
  }
}
.freeBox__pU51 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetBodyText {
  font-size: 18px;
  color: #535353;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetBodyText {
    font-size: 15px;
  }
}
.freeBox__vJgnD {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__vJgnD {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTagsText {
    font-size: 8px;
  }
}
.freeBox___6SvdS {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
  display: none;
  border-radius: 10px;
  padding: 8px;
  border: 1px none #535353;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6SvdS {
    max-width: 100px;
    width: auto;
    max-height: 100px;
    height: auto;
    align-items: center;
    justify-content: center;
    border-style: none;
  }
}
.svg {
  position: relative;
  object-fit: cover;
  width: 50px;
  height: 50px;
  color: #535353;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
}
.freeBox__u5UGp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetClicksText {
  font-weight: 700;
  color: #535353;
  text-align: center;
}
