.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  background: #f1f8fe;
  position: relative;
  min-width: 0;
  border-radius: 4px;
  padding: 12px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    max-width: 100%;
  }
}
.freeBox___0Gl6 {
  height: 100%;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
  padding: 8px;
}
.freeBox___0Gl6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 0;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox___0Gl6 > :global(.__wab_flex-container) > *,
.freeBox___0Gl6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0Gl6 > :global(.__wab_flex-container) > picture > img,
.freeBox___0Gl6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0Gl6 {
    padding: 0px;
  }
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 80px;
  align-self: center;
  border-radius: 10px;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__fd5P4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-left: calc(10px + 2px) !important;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__fd5P4 {
    margin-left: calc(10px + 2px) !important;
  }
}
.freeBox___9MP91 {
  display: flex;
  padding-bottom: 0px;
  flex-direction: row;
}
.slotTargetChildren {
  font-family: "Oxygen", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: rgba(36, 41, 48, 1);
  text-align: left;
  line-height: 24px;
  letter-spacing: 0em;
}
.freeBox__rgW2 {
  display: flex;
  align-self: stretch;
  position: relative;
  padding-top: 5px;
  flex-direction: row;
}
.slotTargetSlot {
  font-family: "Oxygen", sans-serif;
  font-size: 14px;
  color: rgba(78, 93, 112, 1);
  line-height: 20px;
  letter-spacing: 0em;
}
.form {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.emailInput:global(.__wab_instance) {
  max-width: 100%;
}
.svg__nB9YP {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__w7Jmw {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.emailSendButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-left: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .emailSendButton:global(.__wab_instance) {
    margin-left: 8px;
  }
}
.svg__fwFuI {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___2Xr0R {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.toastPlaceholder {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
