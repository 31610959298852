.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__k9Yic {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 960px;
  min-width: 0;
  padding: 8px;
}
.freeBox__dckJd {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__dckJd > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__dckJd > :global(.__wab_flex-container) > *,
.freeBox__dckJd > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dckJd > :global(.__wab_flex-container) > picture > img,
.freeBox__dckJd
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__xx2Nk {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  padding: 8px;
}
.freeBox__xx2Nk > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.freeBox__xx2Nk > :global(.__wab_flex-container) > *,
.freeBox__xx2Nk > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xx2Nk > :global(.__wab_flex-container) > picture > img,
.freeBox__xx2Nk
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 30px;
}
.freeBox__yOIuF {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__yOIuF > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 30px);
  width: calc(100% + 30px);
}
.freeBox__yOIuF > :global(.__wab_flex-container) > *,
.freeBox__yOIuF > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__yOIuF > :global(.__wab_flex-container) > picture > img,
.freeBox__yOIuF
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 30px;
}
.contactUs {
  position: relative;
  width: auto;
  height: 25px;
  color: #54a0e0;
  padding-right: 0px;
  font-family: "Oxygen", sans-serif;
}
.privacyPolicy {
  position: relative;
  width: auto;
  color: #54a0e0;
  font-family: "Oxygen", sans-serif;
}
.freeBox__ktKdb {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 0px;
}
.freeBox__ktKdb > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__ktKdb > :global(.__wab_flex-container) > *,
.freeBox__ktKdb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ktKdb > :global(.__wab_flex-container) > picture > img,
.freeBox__ktKdb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.twitter {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 28px;
  flex-shrink: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 23px;
  height: 26px;
  flex-shrink: 0;
}
.freeBox__yuF4C {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 15px;
  min-width: 0;
  padding: 8px;
}
.resourcePal2023AllRightsReserved {
  text-align: center;
  font-size: 12px;
  font-family: "Oxygen", sans-serif;
}
.link {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  color: #54a0e0;
  min-width: 0;
}
