.MuiChip-label{
    font-size: 12px;
}

.MuiInputBase-root {
    padding: 2px !important;
}

.typedHero{
    font-size: 2em; display: block; height: 150px;
}

@media (max-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .typedHero{
        height: 400px;
    }
}
@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */
    .typedHero{
        height: 300px;
    }
}
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .typedHero{
        height: 200px;
    }
}
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

    .typedHero{
        height: 150px;
    }
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
    .typedHero{ height: 100px}
}
@media (min-width:1281px) { /* hi-res laptops and desktops */}