.buttonLinkSecondary {
  width: auto;
  height: auto;
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  padding: 12px 16px;
}
.buttonLinkSecondary > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.buttonLinkSecondary > :global(.__wab_flex-container) > *,
.buttonLinkSecondary
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.buttonLinkSecondary > :global(.__wab_flex-container) > picture > img,
.buttonLinkSecondary
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.slotTargetText {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(36, 41, 48, 1);
  text-align: center;
  line-height: 20px;
  letter-spacing: 0em;
}
