.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.freeBox__odF1N {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__odF1N {
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  padding: 8px 0px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__bJoWr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 0px;
}
.img {
  position: relative;
  object-fit: cover;
  object-position: 50% 50%;
  max-height: 256px;
  width: 100%;
  max-width: 256px;
  min-width: 0;
}
.img > picture > img {
  object-fit: cover;
  object-position: 50% 50%;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% 50%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    align-self: center;
  }
}
.column__c29Ie {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__c29Ie {
    align-items: center;
    justify-content: center;
  }
}
.freeBox__clWqc {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 15px;
  min-width: 0;
  padding: 0px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__clWqc {
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
}
.freeBox__c7SsI {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__c7SsI {
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
}
.resourceName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.slotTargetResourceName {
  font-size: 30px;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetResourceName {
    font-size: 20px;
  }
}
.freeBox__grSgF {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__grSgF {
    display: none;
  }
}
.svg__vJcda {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.span {
  width: auto;
  height: auto;
  max-width: 100%;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.freeBox__oRihh {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
  margin: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__oRihh {
    max-width: 100%;
    margin: 18px 0px 0px;
  }
}
.visitSite:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
}
.svg__u3VbC {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__z40Ns {
  padding-right: 0px;
  color: #ffffff;
}
.svg___2SJnh {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.shareButtons {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-grow: 0;
  flex-direction: row;
  align-self: flex-start;
  min-width: 0;
  padding: 8px 0px;
  margin: 0px;
}
.shareButtons > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.shareButtons > :global(.__wab_flex-container) > *,
.shareButtons > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.shareButtons > :global(.__wab_flex-container) > picture > img,
.shareButtons
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .shareButtons {
    max-width: 100%;
  }
}
.shareSite:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__xVb64 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__d3Mi0 {
  font-size: 14px;
  color: #252a31;
}
.svg__quw0L {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button___2Pm3C:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__qrU9D {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text___2Qc7S {
  font-size: 14px;
}
.svg__tbxcM {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button__oTmQj:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__vXdCw {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__igaAt {
  font-size: 14px;
}
.svg__oW1M6 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.newsLetter:global(.__wab_instance) {
  position: relative;
  margin: 8px 0px;
}
.freeBox__tsU5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  padding: 0px;
}
.slotTargetResourceDescription {
  color: #4f5e71;
  font-size: 14px;
}
