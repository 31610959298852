.root {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  min-height: 0;
  border-radius: 8px;
  padding: 45px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
  }
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___0UcGc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__ubIi {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 24px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ubIi {
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
}
.freeBox__g6Dbd {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  min-width: 0;
}
.img {
  position: relative;
  object-fit: cover;
  margin-bottom: 0;
  height: 270px;
  width: 200px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img {
    width: 100%;
    min-width: 0;
  }
}
.freeBox__a6Dwi {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__a6Dwi {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}
.freeBox__tWGB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox___8UjhH {
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: row;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___8UjhH {
    display: flex;
  }
}
.resourceNameMobile {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 30px;
  min-width: 0;
}
.freeBox__nbMpi {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nbMpi {
    justify-content: flex-start;
    align-items: center;
    padding-left: 0px;
  }
}
.svg__gby9S {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__gby9S {
    display: block;
  }
}
.link__seeJx {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 4px;
  flex-direction: row;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__seeJx {
    display: flex;
  }
}
.slotTargetResourceLinkMobile {
  font-size: 14px;
  color: #4f5e71;
}
.freeBox__t2Jd {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__t2Jd {
    justify-content: flex-start;
    align-items: center;
    width: 96px;
    height: auto;
    flex-shrink: 0;
    display: none;
    border: 1px solid #e8edf1;
  }
}
.svg__peIzw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__peIzw {
    display: block;
  }
}
.freeBox__oUs1N {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 4px;
  flex-direction: row;
  min-width: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__oUs1N {
    display: flex;
  }
}
.slotTargetResourceClicks2 {
  font-size: 12px;
  font-weight: 500;
  color: #252a31;
  white-space: pre;
}
.freeBox__i1FkR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetResourceDescription {
  color: #4f5e71;
  font-size: 14px;
}
.freeBox___65Fcw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetResourceDescription2 {
  color: #4f5e71;
}
.column__akMzc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__aTfI1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__aTfI1 {
    padding-right: 0px;
    padding-left: 0px;
  }
}
.freeBox__iBq4Q {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__grlHt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox___71McY {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 0;
}
.resourceName {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 30px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .resourceName {
    display: none;
  }
}
.freeBox__nGr4H {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__nGr4H {
    display: none;
  }
}
.svg__u3F3Z {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.link__ii6Fy {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetResourceLink {
  font-size: 14px;
  color: #4f5e71;
}
.freeBox__tivl {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #e8edf1;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__tivl {
    display: none;
  }
}
.svg__cmOO {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__iKb3M {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.slotTargetResourceClicks {
  font-weight: 500;
  color: #252a31;
}
.freeBox__efmVz {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  max-width: 100%;
  background: #f5f7f9;
  flex-shrink: 0;
  display: none;
  border-radius: 8px;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__efmVz {
    display: none;
  }
}
.svg__pVl79 {
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__pVl79 {
    display: none;
  }
}
.newsLetter:global(.__wab_instance) {
  position: relative;
  margin: 12px 12px 12px 0px;
}
.freeBox__mxb3T {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 90%;
  align-self: flex-start;
  min-width: 0;
  padding: 0px;
  margin: 20px 20px 20px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__mxb3T {
    max-width: 100%;
  }
}
.visitSite:global(.__wab_instance) {
  max-width: 100%;
  width: 100%;
  flex-shrink: 1;
  min-width: 0;
}
.svg__plAtb {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__ws1C5 {
  padding-right: 0px;
  color: #ffffff;
}
.svg__mZbFy {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__x3Ul {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 90%;
  flex-shrink: 1;
  flex-grow: 0;
  flex-direction: row;
  align-self: flex-start;
  margin-top: -10px;
  min-width: 0;
  padding: 8px 0px;
}
.freeBox__x3Ul > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__x3Ul > :global(.__wab_flex-container) > *,
.freeBox__x3Ul > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__x3Ul > :global(.__wab_flex-container) > picture > img,
.freeBox__x3Ul
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__x3Ul {
    max-width: 100%;
  }
}
.shareSite:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
}
.svg__pcVb7 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__vaVRq {
  font-size: 14px;
  color: #252a31;
}
.svg__o91V {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button__tbo86:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
  display: none;
}
.svg__iwCd {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__sUmgA {
  font-size: 14px;
}
.svg__cVKgT {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.button__r6WgX:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  min-width: 0;
  display: none;
}
.svg__uiVdz {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__r8Glk {
  font-size: 14px;
}
.svg__osG2L {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
