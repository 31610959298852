.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.header:global(.__wab_instance) {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.freeBox__kJyGx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  justify-self: flex-start;
  max-width: none;
  margin-top: 20px;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px 15%;
  border-bottom: 0.5px solid #00000033;
}
.h2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-top: 100px;
  min-width: 0;
  padding: 8px;
}
.text__pRFu {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 30px;
  min-width: 0;
  margin: 25px 10px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__pRFu {
    font-size: 18px;
  }
}
.exploreProductsButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 100px;
  margin-top: 25px;
}
.svg__nKp7I {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__cPo0N {
  font-size: 30px;
  height: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__cPo0N {
    font-size: 20px;
  }
}
.svg__aIybT {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox___6CuLq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 90px;
  min-width: 0;
  padding: 8px;
}
.img__jsBnO {
  object-fit: cover;
  max-width: 100%;
  width: 60%;
}
.img__jsBnO > picture > img {
  object-fit: cover;
}
.img__jsBnO > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__pLo71 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  font-size: 30px;
  min-width: 0;
  margin: 10px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__pLo71 {
    font-size: 18px;
  }
}
.validateIdeaButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hdHDh {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__fCjwg {
  font-size: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fCjwg {
    font-size: 20px;
  }
}
.svg__z6JUf {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__pvrE {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 70px;
  min-width: 0;
  padding: 8px;
}
.img__aJfnt {
  object-fit: cover;
  max-width: 100%;
}
.img__aJfnt > picture > img {
  object-fit: cover;
}
.img__aJfnt > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__svhe9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 30px;
  text-align: center;
  margin-top: 15px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__svhe9 {
    font-size: 18px;
  }
}
.buildIdeaButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__d9RJ {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__ofZan {
  font-size: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__ofZan {
    font-size: 20px;
  }
}
.svg___2TVoO {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox___2WnYx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.img__hGuWc {
  object-fit: cover;
  max-width: 100%;
}
.img__hGuWc > picture > img {
  object-fit: cover;
}
.img__hGuWc > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__fpW5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 30px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__fpW5 {
    font-size: 18px;
  }
}
.launchIdeaButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__tmre0 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__lYc0P {
  font-size: 30px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__lYc0P {
    font-size: 20px;
  }
}
.svg__ehGtg {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__g3Kn4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  min-width: 0;
  padding: 8px;
}
.newsLetter:global(.__wab_instance) {
  max-width: 100%;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
