.explorePage {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.explorePage > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .explorePage > * {
    grid-column: 4;
  }
}
.header:global(.__wab_instance) {
  position: absolute;
  width: 100%;
  height: 126px;
  left: 0px;
  top: 0px;
  z-index: 100;
  transition-property: all;
  transition-duration: 1s;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
@media (min-width: 0px) and (max-width: 768px) {
  .header:global(.__wab_instance) {
    margin-top: 0px;
    height: auto;
    top: 0px;
    left: 0px;
    position: sticky;
  }
}
.titleSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  margin-top: 115px;
  height: auto;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  padding: 8px;
  border-bottom: 0.5px solid #00000033;
}
@media (min-width: 0px) and (max-width: 768px) {
  .titleSection {
    height: auto;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0px 0px;
  }
}
.fromIdeaToProduct {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: center;
  margin-top: 0px;
  font-size: 28px;
  font-weight: 700;
  font-family: "Oxygen", sans-serif;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .fromIdeaToProduct {
    padding-top: 0px;
    margin-top: 0px;
    font-size: 30px;
    padding-bottom: 1px;
    padding-left: 0px;
  }
}
.subtitleText {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 22px;
  padding-left: 0px;
  padding-top: 0px;
  font-weight: 400;
  font-family: "Oxygen", sans-serif;
  min-width: 0;
  min-height: 0;
  margin: 0px 10px 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .subtitleText {
    font-size: 20px;
    padding-top: 3px;
    margin-bottom: 8px;
    text-align: center;
    padding-left: 0px;
    height: auto;
  }
}
.img__nsFcb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 369px;
  flex-shrink: 0;
}
.img__nsFcb > picture > img {
  object-fit: cover;
}
.img__nsFcb > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__nsFcb {
    height: 206px;
    flex-shrink: 0;
  }
}
.resourcesSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: none;
  justify-self: flex-start;
  margin-top: 23px;
  position: relative;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  min-height: 0;
  padding: 0px 7% 8px;
  border-bottom: 0.5px solid #00000033;
}
@media (min-width: 0px) and (max-width: 768px) {
  .resourcesSection {
    height: auto;
    overflow: auto;
    border-bottom-color: #00000033;
  }
}
.freeBox___6UXwV {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  z-index: 10;
  margin-top: 0px;
  flex-direction: column;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6UXwV {
    height: auto;
  }
}
.newsLetterMobile:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 8px;
}
.columns__qX2Bs {
  --plsmc-rc-col-gap: 0px;
}
.columns__qX2Bs {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 0px;
}
.columns__qX2Bs > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__qX2Bs > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qX2Bs {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__qX2Bs > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__knt8P {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.freeBox__z4G1M {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 0;
  padding: 8px;
}
.freeBox__z4G1M > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__z4G1M > :global(.__wab_flex-container) > *,
.freeBox__z4G1M > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__z4G1M > :global(.__wab_flex-container) > picture > img,
.freeBox__z4G1M
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.resourcesFilterDropdownMenu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
}
.categoriesDropdownMenu:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  min-width: 0;
  display: none;
}
.resourceFiltersSlotElement {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: auto;
  min-width: 0;
  display: none;
}
.buttonLinkPrimary:global(.__wab_instance) {
  position: relative;
}
.buttonLinkSecondary:global(.__wab_instance) {
  position: relative;
}
.column__vQr8R {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__vQr8R {
    padding: 8px;
  }
}
.textInput__vfy5M:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__gt1Qz {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__aA2R {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.columns__voHxy {
  --plsmc-rc-col-gap: 0px;
}
.columns__voHxy {
  display: flex;
  position: sticky;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0px;
  top: 0px;
  z-index: 1;
  min-width: 0;
}
.columns__voHxy > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 8 / 12);
}
.columns__voHxy > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__voHxy {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__voHxy > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ltq8K {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.resourceList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.resourceCard:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.text__rUkM {
  font-family: "Oxygen", sans-serif;
}
.text__saLjs {
  font-family: "Oxygen", sans-serif;
}
.text___4BK3 {
  font-family: "Oxygen", sans-serif;
}
.column__mdxmq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.signupSection {
  width: 100%;
  height: auto;
  position: sticky;
  top: 0px;
  left: 0px;
  flex-direction: column;
  z-index: 1;
  min-width: 0;
  display: none;
}
.signupSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 33px);
  height: calc(100% + 33px);
}
.signupSection > :global(.__wab_flex-container) > *,
.signupSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.signupSection > :global(.__wab_flex-container) > picture > img,
.signupSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 33px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .signupSection {
    flex-direction: column;
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .signupSection > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .signupSection > :global(.__wab_flex-container) > *,
  .signupSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .signupSection > :global(.__wab_flex-container) > picture > img,
  .signupSection
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox__un04K {
  background: #f0f7fd;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  min-width: 0;
  border-radius: 8px;
  padding: 12px;
}
.freeBox__un04K > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__un04K > :global(.__wab_flex-container) > *,
.freeBox__un04K > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__un04K > :global(.__wab_flex-container) > picture > img,
.freeBox__un04K
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__us89B {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.freeBox__us89B > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__us89B > :global(.__wab_flex-container) > *,
.freeBox__us89B > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__us89B > :global(.__wab_flex-container) > picture > img,
.freeBox__us89B
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__aWBta {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.freeBox__aWBta > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__aWBta > :global(.__wab_flex-container) > *,
.freeBox__aWBta > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aWBta > :global(.__wab_flex-container) > picture > img,
.freeBox__aWBta
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.img___33R7I {
  position: relative;
  object-fit: cover;
  max-width: 80px;
  align-self: center;
  border-radius: 10px;
}
.img___33R7I > picture > img {
  object-fit: cover;
}
.img___33R7I > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox___3Ujai {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: calc(10px + 2px) !important;
  min-width: 0;
}
.text__fYzp9 {
  display: block;
  font-size: 15px;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 24px;
  color: rgba(36, 41, 48, 1);
  text-align: left;
  padding-bottom: 0px;
}
.text___5BkK {
  display: block;
  font-size: 14px;
  font-family: "Oxygen", sans-serif;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(78, 93, 112, 1);
  align-self: stretch;
  position: relative;
  padding-top: 5px;
}
.freeBox__os5Bu {
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(185, 198, 212, 1);
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  border-radius: 6px;
  padding: 12px;
}
.freeBox__os5Bu > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__os5Bu > :global(.__wab_flex-container) > *,
.freeBox__os5Bu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__os5Bu > :global(.__wab_flex-container) > picture > img,
.freeBox__os5Bu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.signupEmailInput {
  height: 11px;
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  flex-direction: row;
  cursor: default;
  backdrop-filter: drop-shadow(10px 10px 10px #ffffff);
  -webkit-backdrop-filter: drop-shadow(10px 10px 10px #ffffff);
}
.signupEmailInput > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.signupEmailInput > :global(.__wab_flex-container) > *,
.signupEmailInput > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.signupEmailInput > :global(.__wab_flex-container) > picture > img,
.signupEmailInput
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.signupInput {
  display: block;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  letter-spacing: 0em;
  line-height: 20px;
  color: rgba(104, 124, 148, 1);
  position: relative;
  height: auto;
}
.signupEmailButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 0px;
  margin-top: calc(0px + 16px) !important;
  height: 39px;
  margin-left: 0px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .signupEmailButton:global(.__wab_instance) {
    margin-top: calc(0px + 16px) !important;
  }
}
.svg___4We2D {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__jdvqg {
  font-size: 18px;
  height: auto;
  font-weight: 400;
  text-align: center;
  font-family: "Oxygen", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__jdvqg {
    font-size: 20px;
  }
}
.svg___7PO0 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.feedbackSection {
  width: 100%;
  height: auto;
  position: sticky;
  top: 0px;
  left: 0px;
  flex-direction: column;
  z-index: 1;
  margin-top: 20px;
  min-width: 0;
  display: none;
}
.feedbackSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 33px);
  height: calc(100% + 33px);
}
.feedbackSection > :global(.__wab_flex-container) > *,
.feedbackSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.feedbackSection > :global(.__wab_flex-container) > picture > img,
.feedbackSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 33px;
}
.freeBox__hgvqg {
  background: rgba(240, 247, 253, 1);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  min-width: 0;
  border-radius: 8px;
  padding: 12px;
}
.freeBox__hgvqg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.freeBox__hgvqg > :global(.__wab_flex-container) > *,
.freeBox__hgvqg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hgvqg > :global(.__wab_flex-container) > picture > img,
.freeBox__hgvqg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.freeBox__kpf6L {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
}
.freeBox__kpf6L > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__kpf6L > :global(.__wab_flex-container) > *,
.freeBox__kpf6L > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__kpf6L > :global(.__wab_flex-container) > picture > img,
.freeBox__kpf6L
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__e7Yi {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
}
.freeBox__e7Yi > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.freeBox__e7Yi > :global(.__wab_flex-container) > *,
.freeBox__e7Yi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__e7Yi > :global(.__wab_flex-container) > picture > img,
.freeBox__e7Yi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.freeBox__tydcY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-left: calc(10px + 2px) !important;
  min-width: 0;
}
.text__kSav2 {
  display: block;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 24px;
  color: rgba(36, 41, 48, 1);
  text-align: left;
}
.feedbackButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 0px;
  margin-top: calc(0px + 16px) !important;
  height: 44px;
  margin-left: 0px;
  width: 316px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .feedbackButton:global(.__wab_instance) {
    margin-top: calc(0px + 16px) !important;
  }
}
.svg__uUv {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__nnGzo {
  font-size: 18px;
  height: auto;
  font-weight: 400;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__nnGzo {
    font-size: 20px;
  }
}
.svg__uRPbU {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.newsLetter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .newsLetter:global(.__wab_instance) {
    display: none;
  }
}
.bottomNewsLetter:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text__f3Tum {
  height: auto;
  font-size: 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__f3Tum {
    font-size: 18px;
  }
}
.text___6Tibm {
  font-size: 18px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___6Tibm {
    font-size: 16px;
  }
}
.feedbackModal:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .feedbackModal:global(.__wab_instance) {
    display: none;
  }
}
.freeBox___10ODd {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-top: 10px;
}
.resourceInfoModal:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  justify-self: flex-start;
  min-width: 70%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .resourceInfoModal:global(.__wab_instance) {
    justify-self: flex-start;
    margin-right: 10px;
    margin-left: 10px;
    max-width: 90%;
    min-width: none;
    width: 100%;
  }
}
.resourceModal:global(.__wab_instance) {
  margin-top: 0px;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .resourceModal:global(.__wab_instance) {
    width: 100%;
    align-self: auto;
    height: 100%;
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
    min-width: 0;
  }
}
.text__ldXcj {
  font-family: "Oxygen", sans-serif;
}
.ol {
  display: flex;
  flex-direction: column;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__bxAfo {
    display: none;
  }
}
.resourceModal2:global(.__wab_instance) {
  max-width: 100%;
}
.text___0QOdz {
  text-align: left;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___0QOdz {
    text-align: center;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__e84We {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }
}
