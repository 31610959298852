.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    justify-items: center;
    align-content: flex-start;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 126px;
  width: 100%;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
}
.freeBox___7J2CN {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 22px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___7J2CN {
    height: 100%;
    width: auto;
    justify-self: flex-start;
    min-height: 0;
  }
}
.freeBox___6GaSd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  margin-top: 18px;
  min-width: 0;
  padding: 18px 8px 18px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6GaSd {
    background: none;
  }
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-size: 45px;
  background: none;
  padding-left: 9px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1 {
    background: none;
    color: #000000;
  }
}
.freeBox__uBpGv {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__uBpGv {
    height: 100%;
    min-height: 0;
  }
}
.privacyPolicy {
  position: relative;
  width: 1036px;
  height: auto;
  max-width: 100%;
  font-size: 16px;
  padding-top: 10px;
  font-family: "Inter", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .privacyPolicy {
    height: 100%;
    min-height: 0;
  }
}
.h5__dLid {
  margin-top: 15px;
}
.text__cYgjR {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.h5__if9D6 {
  margin-top: 15px;
}
.text__auwmF {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-bottom: 0px;
  min-width: 0;
}
.h5__uBuO {
  margin-top: 15px;
}
.text___3P8Ze {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.text__bOhu6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 20px;
  font-weight: 700;
  min-width: 0;
}
.footer:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer:global(.__wab_instance) {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
