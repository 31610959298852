.description > p,h1,h3,li > p {
    margin-block: auto;
}

.description > ul {
    display: grid;
}

.description h3 {
    display: contents;
}

ul {
    margin-block: auto !important;
}

.description {
    font-size: larger;
}

.markdownBox {
    flex-direction: column !important;
}